import React from 'react';
import Layout from '../../components/Layout';

function Thanks() {
  return (
    <Layout>
      <section className="section">
        <div className="container">
          <div className="content">
            <div className="columns is-centered">
              <div className="column is-full">
                <h1 className="title is-size-2 is-family-secondary has-text-info has-text-centered">
                  Thank you!
                </h1>
                <p className="is-size-5 has-text-centered">We will get back to you shortly</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default Thanks;
